.s-cta {
  --borderCirclePrimary: var(--borderNeutralSubtle);
}

.s-cta {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.s-cta__body {
  position: relative;
  height: 38.75rem;
  width: 100%;
}

.s-cta__title,
.s-cta__title-bottom,
.s-cta__title-medium,
.s-cta__title-top {
  font-size: 8rem;
  line-height: 3.5rem;
  letter-spacing: -0.00390625em;
}

.s-cta__title-top {
  margin-bottom: 11.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 1.875rem;
  padding-top: 6.5rem;
}

.s-cta__title-medium {
  margin-bottom: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.s-cta__title-bottom {
  margin-left: 19.1875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.s-cta__background {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -10;
  height: 32rem;
  width: 32rem;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.s-cta__background img {
  height: 100%;
  width: 100%;
  --tw-rotate: 20deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-filter: drop-shadow(0px 4px 100px rgba(255, 0, 0, 0.85));
  filter: drop-shadow(0px 4px 100px rgba(255, 0, 0, 0.85));
}

.s-cta__background__circle-small {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 26.125rem;
  width: 26.125rem;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--borderCirclePrimary);
}

.s-cta__background__circle-medium {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 32rem;
  width: 32rem;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--borderCirclePrimary);
}

.s-cta__background__circle-big {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 38.75rem;
  width: 38.75rem;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--borderCirclePrimary);
}

.s-cta__btn {
  position: absolute;
  top: 1.0625rem;
  right: 12.9375rem;
}

@media (max-width: 89.9988em) {
  .s-cta__title-bottom {
    margin-left: 5rem;
  }

  .s-cta__btn {
    right: 0px;
  }
}

@media (max-width: 61.9988em) {

  .s-cta__title,
  .s-cta__title-bottom,
  .s-cta__title-medium,
  .s-cta__title-top {
    font-size: 6rem;
  }

  .s-cta__btn {
    top: 7.5rem;
    height: 12.5rem;
    width: 12.5rem;
  }
}

@media (max-width: 47.9988em) {
  .s-cta {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .s-cta__title,
  .s-cta__title-bottom,
  .s-cta__title-medium,
  .s-cta__title-top {
    font-size: 3.5rem;
    line-height: 4rem;
    letter-spacing: -0.0267857143em;
  }

  .s-cta__title-top {
    margin-bottom: 11.0625rem;
    padding-left: 0px;
    padding-top: 0px;
  }

  .s-cta__title-medium {
    margin-bottom: 2rem;
  }

  .s-cta__title-bottom {
    margin-left: 0px;
  }

  .s-cta__background {
    height: 21rem;
    width: 21rem;
  }

  .s-cta__background img {
    position: absolute;
    left: -2.5rem;
    top: -2.5rem;
    z-index: 20;
    -webkit-filter: drop-shadow(30px 94px 60px rgba(0, 0, 0, 0.22));
    filter: drop-shadow(30px 94px 60px rgba(0, 0, 0, 0.22));
  }

  .s-cta__background__circle-small {
    height: 22rem;
    width: 22rem;
    opacity: 0.12;
  }

  .s-cta__background__circle-medium {
    display: none;
  }

  .s-cta__background__circle-big {
    display: none;
  }

  .s-cta__btn {
    top: auto;
    bottom: 4.875rem;
    right: 2.3125rem;
    height: 9rem;
    width: 9rem;
  }
}

@media (max-width: 23.4375em) {

  .s-cta__title,
  .s-cta__title-bottom,
  .s-cta__title-medium,
  .s-cta__title-top {
    font-size: 2.5rem;
  }

  .s-cta__background {
    height: 100%;
    max-height: 18rem;
    width: 100%;
    max-width: 18rem;
  }

  .s-cta__background__circle-small {
    height: 17.5rem;
    width: 17.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .s-cta {
    --borderCirclePrimary: var(--borderNeutralSubtle);
  }
}